@import url("https://fonts.googleapis.com/css?family=Special+Elite&display=swap");
/*@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500|Special+Elite&display=swap");*/

* {
  box-sizing: border-box;
}
html {
  padding: 0;
  margin: 0;
  font-size: calc(1em + 1vw);
  font-family: "Special Elite", cursive;
}
body {
  padding: 0;
  margin: 0;
  overscroll-behavior: contain;
  background: #212121;
  color: #fdf5e8;
  text-align: center;
  font-family: "Special Elite", cursive;
  overflow-x: hidden;
}
p {
  word-break: break-word;
}
hr {
  display: block;
  width: 100%;
}
label {
  display: inline-block;
}
h1,
h2,
h3,
h4,
h5 {
  line-height: 1.1;
  margin: 0.5em;
  font-weight: 400;
}
.full-over {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
}
.full-over div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Service Worker Update */
#snackbar {
  min-width: 250px;
  background-color: #c72f40;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
}
/* Service Worker */
